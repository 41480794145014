<template>

  <template v-if="videoData">
    <!-- {{ videoData }} -->
    <!-- autoplay="false"  -->


    <div v-if="!videoData.is_ready" class="video_empty_working" >

      <img loading="lazy" 
        :src="videoData.item_photo.size_m" 
        class="cover" 
        alt=""
      >

      <div class="place">
        <i class="cloading"></i> Processing 
      </div>

    </div>

    <div v-else-if="videoData.is_banned" class="video_empty_working" >

      <img loading="lazy" 
        :src="videoData.item_photo.size_m" 
        class="cover" 
        alt=""
      >

      <div class="place">
        Banned
      </div>

    </div>

    <div v-else-if="!videoData.is_moderated" class="video_empty_working" >

      <img loading="lazy" 
        :src="videoData.item_photo.size_m" 
        class="cover" 
        alt=""
      >

      <div class="place">
        <i class="cloading"></i> Moderating 
      </div>

    </div>

    <video loading="lazy" 
      v-else
      class="vjs-tech" 
      v-bind:autoplay="autoplay !== 'false' ? autoplay : null"
      v-bind:controls="controls"
      preload="auto" 
      playsinline="playsinline"   
      :poster="videoData.item_photo.size_m"
      >
      <source :src="videoData.size_m" type="video/mp4">      
    </video>


    <img loading="lazy" 
      :src="videoData.item_photo.size_m" 
      class="bg" 
    />



    <div
      v-if="header === 'true'"
      class="header"
    >

      <div class="copylink"></div>

      <div class="embed" @click="share = true"></div>

      <a target="_blank" :href="videoData.item_photo.link_week" class="logo"></a>

    </div>

    <div v-if="share" class="embedWindow">
      <textarea 
        ref="code" 
        id="code_frame" 
        class="textarea_embed"
        v-model="textEmbed"
      >
      </textarea>
      <div class="btns">
        <button @click="copy" class="btn_close_copy">Copy link</button>
        <button @click="share = false" class="btn_close_embed">Close</button>
      </div>
    </div>

    
     

  </template>

</template>

<script setup lang="ts">


definePageMeta({
  layout: "empty",
  auth: false
})

const route = useRoute();
const { $api, $ga, $head } = useNuxtApp();
const video_id = ref(route.params['id']);
const share = ref(false);
const code = ref(null);
const defaultLink = ref('https://growdiaries.com/video/');
const autoplay = ref(route.query['autoplay'] ? route.query['autoplay'] : 'false');
const header = ref(route.query['header'] ? route.query['header'] : 'true');
const controls = ref(route.query['controls'] ? route.query['controls'] : 'true');

const link = computed(() => {
  return defaultLink.value + video_id.value;
})

const loadData = async function() {   
  const response = await $api.getVideo(video_id.value)  
  return response;  
}

const copy = function(){
  if(!code.value) return;
  code.value.select();
  document.execCommand('copy');
}


const textEmbed = computed(() => {
  return `<iframe loading="lazy" width="640" height="480" frameborder="0" allowfullscreen mozallowfullscreen webkitallowfullscreen src="${link.value}"></iframe>`;
})

const { status: videoDataStatus,  data: videoData } = await useLazyAsyncData('videoData', async () => await loadData())


// useHead(useNuxtApp().$head.getVideo(videoData));

</script>

<style scoped>

html,
body{
  width: 100%;
  height: 100%!important;
  margin: 0px;
  padding: 0px;
  background-color: black;
}

.video_empty{
  width: 100%;
  height: 50px;
  display: block;
  /* width: 300px; */
  /* height: 40px; */
  margin: auto auto;
  vertical-align: middle;
  text-align: center;
  padding-top: 33%;
  color: white;
}
.video_empty_working{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: auto auto;

}
.video_empty_working .cover{
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto auto;
}
.video_empty_working .place{
  position: absolute;
  color: white;
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  font-family: var(--gd-text-font-family);
  text-shadow: 0 0 13px BLACK;
}
.video_empty_working .place .cloading{
  position: relative;
  font-size: 0.8rem;
  top: initial;
  left: initial;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}
.video_empty_working .place .cloading::before{

}
.video_empty_working .place .cloading::after{
  display: none;
}
.video_empty_working{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: auto auto;

}
.video_empty_working .cover{
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto auto;
}
.video_empty_working .place{
  position: absolute;
  color: white;
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  font-family: var(--gd-text-font-family);
  text-shadow: 0 0 13px BLACK;
}
.video_empty_working .place .cloading{
  position: relative;
  font-size: 0.8rem;
  top: initial;
  left: initial;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}
.video_empty_working .place .cloading::before{

}
.video_empty_working .place .cloading::after{
  display: none;
}
.embed{

    position: fixed;
    right: 56px;
    top: 10px;
    z-index: 11040; 
    background-color: rgba(0, 0, 0, 0.47);
    border-radius: 5px;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 100%;
    padding: 20px;
    background-image: url("/images/player/embed.png");
    background-size: 80%;
    background-position: 50% 50%;
}
.embed:hover{ 
    background-color: black;
}
.copylink{

    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 11040; 
    background-color: rgba(0, 0, 0, 0.47);
    border-radius: 5px;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 100%;
    padding: 20px;
    background-image: url("/images/player/link.png");
    background-size: 80%;
    background-position: 50% 50%;
}
.copylink:hover{
    background-color: black;
}
.logo{

    position: fixed;
    right: 102px;
    top: 10px;
    z-index: 11040;
    width: 150px;
    height: 13px;
    background-color: rgba(0, 0, 0, 0.47);
    border-radius: 5px;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 90%; 
    padding: 20px;
    background-position: 50% 50%;
    background-image: url("/logo/logo_white.png");
}
.logo:hover{
    background-color: black;
}


.embedWindow{
  position: fixed;  
  background-color: rgba(0, 0, 0, 0.72);
  width: calc(300px);
  padding: 20px;
  top: calc(50% - 100px);
  border-radius: 5px;
  left: calc(50% - 150px);
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.textarea_embed{
  width: 100%;
  border-radius: 5px;
  height: 100px;
  font-size: 1rem;
  font-weight: 100;
  color: white;
  font-family: var(--gd-text-font-family);
}
.btns{
  display: flex;
  gap: 1rem;
  width: 100%;
}
.btn_close_embed{
  width: calc(50% - 0.5rem);
  padding: 10px;
  background-color: #6c6c6c;
  border: 0px;
  border-radius: 3px;
  color: white;
  display: inline-block;
  flex-shrink: 0;
}
.btn_close_copy{
  width: calc(50% - 0.5rem);
  padding: 10px;
  background-color: var(--un-primary-back-color);
  border: 0px;
  border-radius: 3px;
  color: white;
  display: inline-block;
  flex-shrink: 0;
}
.embedWindow.open{
  display: inline-block;
}

/* video */
video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-color: #000000b5;
}

.bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(10px);
  z-index: -1;
  pointer-events: none;
}

 
</style>